<template>
    <el-dialog
        :title="mode == 'add' ? '新增工具分类' : '编辑工具分类'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="分类" prop="dimension">
                        <el-select v-model="form.dimension" placeholder="请选择分类">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="分类名称" prop="name">
                        <el-input v-model="form.name" placeholder="请输入分类名称" size="medium"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="排序" prop="seq">
                <el-input-number v-model="form.seq" :min="1" size="medium"></el-input-number>
            </el-form-item>
            <el-form-item label="icon图标" class="upload-form-items">
                <el-upload
                    class="upload-demo"
                    list-type="picture-card"
                    action="none"
                    accept=".png,.jpg,.jpe,.jpeg,.jif,.jfif,.jfi,.PNG,.JPG,.JPEG,.JPE,.svg"
                    :multiple="false"
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleUploadFile">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <div v-if="form.icon">
                    <img :src="form.icon | uploadFileUrl()" alt="" v-if="form.icon.indexOf('http') == -1">
                    <img :src="form.icon" alt="" v-else>
                </div>
            </el-form-item>
            <el-form-item label="描述">
                <el-input type="textarea" :rows="2" v-model="form.description" placeholder="请输入描述内容" size="medium"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                dimension: '', // 0格式 1功能
                name: '',
                seq: 1,
                icon: null,
                description: '',
            },
            rules: {
                dimension: [
                    { required: true, message: '请选择分类', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                seq: [
                    { required: true, message: '请选择排序序号', trigger: 'change' }
                ]
            },
            options: [
                { label: '格式', value: 0 },
                { label: '功能', value: 1 }
            ]
        };
    },
    methods: {
        show(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    dimension: '',
                    name: '',
                    seq: 1,
                    icon: null,
                    description: '',
                }
            } else {
                this.getDetailInfo(row.id)
            }

            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        getDetailInfo(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'tooltypeDetail'
            }).then(res=> {
                if(res.code == 0) {
                    this.form = {
                        id: res.data.id,
                        dimension: res.data.dimension,
                        name: res.data.name,
                        seq: res.data.seq,
                        icon: res.data.icon,
                        description: res.data.description,
                    }
                }
            })
        },
        // 上传图标
        handleUploadFile(file, fileList) {
            // 获取中文件后缀
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const fileFormat = ['png', 'jpg', 'jpe', 'jpeg', 'jif', 'jfif', 'jfi', 'PNG', 'JPG', 'JPEG', 'JPE', 'svg'];
            if (fileFormat.indexOf(fileSuffix) === -1) {
                this.$message.error('上传图标只能是png、jpg、jpe、jpeg、jif、jfif、jfi、PNG、JPG、JPEG、JPE、svg格式');
                return false;
            }
            // 请求接口
            var fd = new FormData();
            fd.append('file', file.raw);
       
            this.$store.dispatch('manage/public', {
                query: fd,
                requestInterfaceName: 'uploadFile'
            }).then(res=>{
                if(res.code == 0) {
                    this.$message.success('上传成功');
                    this.form.icon = res.data;
                } else {
                    this.$message.error(res.message);
                    this.form.icon = null;
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.icon = this.form.icon.slice(this.form.icon.indexOf('dev'));

                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addTooltype' : 'editTooltype',
                        listInterfaceName: 'getTooltypeList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .upload-form-items {
       .el-form-item__content {
            display: flex;
            img {
                width: 100px;
                height: 100px;
                margin-left: 20px;
                border-radius: 3px;
            }
       }
    }
    /deep/ .el-upload {
        width: 120px;
        height: 120px;
        line-height: 124px;
    }
}
</style>