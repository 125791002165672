<template>
    <!-- 管理端 公共表格 -->
    <div class="table-common" :style="{'margin-bottom': isMargin}">
        <el-table
            :data="tableData"
            border>
            <el-table-column v-for="(item, index) in tableColumn" :key="index"
            :type="item.type"
            :prop="item.prop"
            :label="item.label"
            :align="item.align"
            :width="item.width">
            </el-table-column>
            <slot></slot>
        </el-table>
    </div>
</template>
<script>
export default {
    props: {
        isMargin: { // 设置下边距
            type: String,
            default: '20px'
        },
        tableColumn: { // 表格头部数据
            type: Array
        },
        tableData: { // 表格数据
            type: Array
        }
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.table-common {
    /deep/ .el-table {
       
        .el-table__body-wrapper .el-table__body{
            tr {
                td {
                    padding: 0px;
                }
            }
        }
    }
    /deep/ img {
        width: 90px;
        height: 50px;
        padding: 5px 0;
    }
}
</style>