<template>
    <!-- 管理端 公共搜索 -->
    <div>
        <el-form :inline="true" :model="formInline" :class="{'demo-form-inline': true}">
            <el-form-item :label="item.label" v-for="(item, index) in formList" :key="index">
                <el-input v-model="formInline[item.model]" :placeholder="item.placeholder" size="medium" v-if="item.type == 'input'"></el-input>
                <el-select v-model="formInline[item.model]" :placeholder="item.placeholder" size="medium" :disabled="item.isDisabled" :clearable="item.isClearable" v-if="item.type == 'select'">
                    <el-option v-for="keys in item.options" :key="keys.label" :label="keys.label" :value="keys.value"></el-option>
                </el-select>
                <div v-if="item.type == 'date'">
                    <el-date-picker v-model="formInline[item.model]" type="date" placeholder="开始时间"></el-date-picker>-
                    <el-date-picker v-model="formInline[item.models]" placeholder="结束时间"></el-date-picker>
                </div>
                <div v-if="item.type == 'startDate'">
                    <el-date-picker v-model="formInline[item.model]" type="date" placeholder="点击选择时间"></el-date-picker>
                </div>
                <div v-if="item.type == 'endDate'">
                    <el-date-picker v-model="formInline[item.model]" placeholder="点击选择时间"></el-date-picker>
                </div>
            </el-form-item>
            <el-form-item v-if="formBtn">
                <el-button type="primary" size="medium" icon="el-icon-search" @click="onSearch">搜索</el-button>
                <el-button size="medium" icon="el-icon-delete" @click="onResetBtn" v-if="isShowReset">清空</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props: {
        formInline: {
            type: Object,
            default: {}
        },
        formList: {
            type: Array,
            default: []
        },
        formBtn: { // 是否显示操作按钮
            type: Boolean,
            default: true
        },
        isShowReset: { // 是否显示清空按钮
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        }
    },
    methods: {
        onSearch(){
            this.$emit('onSearch');
        },
        onResetBtn() {
            this.$emit('onResetBtn');
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-form {
    .el-form-item {
        .el-button {
            span {
                margin-left: 2px;
            }
        }
    }
}
</style>