<template>
    <div style="padding: 20px;">
       <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon>
       <el-button type="primary" icon="el-icon-plus" size="medium" style="margin: 0 0 20px 0;" @click="handleToolTypeEvent('add')">添加</el-button>
        <template>
            <TableCommon isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
                <el-table-column
                label="分类"
                align="center"
                width="200">
                    <template slot-scope="scope">
                       <span>{{ scope.row.dimension == 0 ? '格式' : '功能' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                label="图标"
                align="center"
                width="200">
                    <template slot-scope="scope">
                        <img :src="scope.row.icon" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center"
                width="130">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" size="medium" @click="handleToolTypeEvent('edit', scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" size="medium" @click="handleToolTypeEvent('remove', scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </TableCommon>
        </template>
        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        
        <addTool ref="addToolPage" :vkey="vkey"></addTool>
    </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from '../../../components/TableCommon.vue';
import addTool from './components/addTool.vue';
export default {
    data() {
        return {
            vkey: 'tooltypePage',
            formInline: {
                dimension: '', // 维度 0格式 1功能
                name: '',
                pageNum: 1,
                pageSize: 10
            },
            formList: [
                { label: '工具名称', model: 'name', placeholder: '请输入工具名称', type: 'input' }
            ],
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '工具名称', prop: 'name', align: 'center' },
                { label: '描述', prop: 'description', align: 'center' },
                { label: '排序', prop: 'seq', align: 'center' },
            ]
        }
    },
    components: {
        SearchCommon,
        TableCommon,
        addTool
    },
    computed: {
        tableData(){
            return this.$store.state.manage.views[this.vkey].list || [];
        },
        total() {
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoadList()
    },
    methods: {
        // 列表
        onLoadList(){
           this.$store.dispatch('manage/list', {
                vkey: this.vkey,
                reset: true,
                query: this.formInline,
                interfaceName: 'getTooltypeList'
           })
        },
        // 搜索
        onSearch() {
            this.formInline.pageNum = 1;
            this.onLoadList();
        },
        // 清空
        onResetBtn() {
            this.formInline = {
                dimension: '',
                name: '',
                pageNum: 1,
                pageSize: 10
            }
            this.onLoadList()
        },
        //按钮操作
        handleToolTypeEvent(type, row) {
            switch(type) {
                case 'add': 
                    this.$nextTick(()=>{
                        this.$refs.addToolPage.show(type)
                    })
                break;

                case 'edit':
                    this.$nextTick(()=>{
                        this.$refs.addToolPage.show(type, row)
                    })
                break;

                case 'remove':
                    this.$confirm('确定是否要删除当前选择的数据?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('manage/remove', {
                            vkey: this.vkey,
                            query: row.id,
                            requestInterfaceName: 'removeTooltype',
                            listInterfaceName: 'getTooltypeList'
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.formInline.pageNum = 1;
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }).catch(() => {});
                break;
            }
        },
        // 分页
        handleCurrentChange(val){
            this.formInline.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
</style>